@use "../../scss/abstracts/" as *;

.topnav {
  @include flex(center, space-between);
  margin-bottom: $spacing;

  .header-label {
    font-size: 30px;
    font-weight: 900;
  }

  .notification-background {
    padding: 10px 10px 3px 10px;
    background-color: #f7f8f8;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s ease-in-out all;
  }

  .notification-background:hover {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.078);
  }
  .notification {
    width: 24px;
  }

  // @include tablet {
  margin-bottom: $mobileSpacing;

  .header-label {
    font-size: 20px;
  }
  // }
}

.sidebar-toggle {
  cursor: pointer;
  display: none;
  position: absolute;
  left: -10px;
  background-color: white;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  transition: 0.2s ease-in-out all;
  border-radius: 50%;

  i {
    border-radius: 50%;
    font-size: 24px;
    transition: 0.2s ease-in-out all;
    color: $mainColor;
  }
}

.sidebar-toggle :hover {
  background-color: #443df6;
  color: white;
  border-radius: 50%;
  i {
    border-radius: 50%;
  }
}
