@use "../scss/abstracts/" as *;

.main {
  padding-right: $sideBarWidth;
  overflow-x: hidden;
  transition: 0.3s all ease;
  background-color: rgb(255, 255, 255);
  padding-right: 10px;

  &__content {
    min-height: 100vh;
    padding: $spacing;
    transition: all 0.5s ease-in-out;
    padding: $mobileSpacing;
    position: relative;
    background-color: rgb(255, 255, 255);
    z-index: 100;
  }

  @include tablet {
    &__content {
      padding: $smallSpacing;
    }
  }
}
.sidebar-open .main {
  padding-right: $sideBarWidth;
}
