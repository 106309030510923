@use "../../scss/abstracts/" as *;
@use "sass:math";

.sidebar {
  @include flex(space-between, flex-start);
  flex-direction: column;
  background-color: #000b18;
  width: $sideBarWidth;
  height: 100vh;
  position: fixed;
  padding: 30px 20px;
  font-weight: 700;

  &__logo {
    width: 100px;
    margin-left: 15px;
    color: rgb(237, 237, 237);
  }

  &__menu {
    @include flex(flex-start, space-between);
    margin-top: 30px;
    flex-direction: column;
    height: 80%;

    &__list {
      width: 100%;

      &__item {
        @include flex(center, flex-start);
        gap: 10px;
        color: rgb(237, 237, 237);
        margin-bottom: 10px;
        padding: 7px 20px;
        border-radius: 8px;

        &.active {
          background-color: $mainColor;
          color: rgb(237, 237, 237);
        }

        &__icon {
          font-size: 20px;
          margin-right: 10px;
          margin-bottom: -3px;
        }

        &__txt {
          font-size: $fontSizeNormal;
        }
      }
    }

    .user__info {
      @include flex(center, center);
      @include centerDiv();
      flex-direction: column;

      &__image {
        border-radius: 100%;
        width: 60px;
        margin-bottom: 10px;
        position: relative;
      }

      &__name {
        font-size: 16px;
      }

      &__circle {
        position: absolute;
        right: 90px;
        background-color: $green;
        border: 3px solid $white;
        border-radius: 50%;
        --size: 12px;
        width: var(--size);
        height: var(--size);
      }
    }

    .logout {
      @include flex();
      @include centerDiv();
      gap: 5px;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 8px;
      color: rgb(237, 237, 237);
      background-color: $mainColor;
      // border: 1px solid $grayColor;

      &__icon {
        font-size: 20px;
        margin-bottom: -5px;
      }

      &__txt {
        font-size: $fontSizeNormal;
      }
    }
  }

  .sidebar-close {
    display: none;
  }

  // @include tablet {
  .sidebar-close {
    display: block;
    position: absolute;
    left: 10px;
    top: 40px;
  }
  // }
}