$bodyBg: #f8f9fd;
$mainBg: #000b18;
$mainColor: #1d2836;
$mainColorSec: #2b3c50;
$grayColor: #c4c4c4;
$dangerColor: #fd523e;
$successColor: #38c4e5;

$purple: #8624db;
$orange: #ff9066;
$white: #fff;
$black: #000;
$green: #14ff00;
$red: #db190c;

$fontFamily: "Nunito Sans", sans-serif;

$fontSizeNormal: 16px;
$fontSizeMedium: 18px;
$fontSizeLarge: 24px;

$sideBarWidth: 240px;
$sideBarLogoHeight: 171px;
$spacing: 30px;
$mobileSpacing: 24px;
$smallSpacing: 16px;
$borderRadius: 30px;

$mobile-width: 450px;
$tablet-width: 670px;
$minComputer-width: 1000px;
